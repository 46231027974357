import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ILoadingProps {
    message?: string;
}

export const Loading: React.FC<ILoadingProps> = ({ message }) => {
    return (
        <Dialog
            disableBackdropClick={true}
            open={true}
            className='loading-dialog'
            aria-labelledby='loading'>
            <DialogContent>
                <div className='text-center'>
                    <CircularProgress />
                </div>
                {message && <div className='loading'>{message}</div>}
            </DialogContent>
        </Dialog>
    );
};

export default Loading;
